"use strict";
window.addEventListener('load', () => {
    if (window.login_status == 225) {
        const loggedOutElements = document.querySelectorAll('#help-portal-fragment .logged-out');
        loggedOutElements.forEach(element => {
            element.style.display = 'none';
        });
    }
    else {
        const loggedInElements = document.querySelectorAll('#help-portal-fragment .logged-in');
        loggedInElements.forEach(element => {
            element.style.display = 'none';
        });
    }
});
